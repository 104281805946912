#left-bottom-controls{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 1em;
    margin-bottom: 26px;
}

#center-top-controls{
    position: fixed;
    width: 40vw;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    padding: 1em;
}

.control-icon-wrapper {
  padding: 0px;
  width: 30px;
  height: 30px;
  background-color: rgb(255, 255, 255);
}

.control-icon-selected {
    fill: #007ac9;
}

#select-basemap {
    font-size: 1em;
}

.map-type-select {

  cursor: pointer;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;

  border: 0 none;
  background-color: rgb(255, 255, 255);

  font-size: 14px;

  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
  
  box-shadow: 2px 5px 10px var(--color-smoke);

  &:hover {
    transition: all 150ms linear;

    opacity: .85;
  }
  
  &:active {
    transition: all 150ms linear;
    opacity: .75;
  }
  
  &:focus {
    outline: 1px dotted #959595;
    outline-offset: -4px;
  }

  &.selected {
      background: #cbcbcb;
      opacity: .75;
      font-weight: 900;
  }
}

.content-contatiner {
  width: 100%;
  height: 100%;
  max-height: 220px;
  overflow-y: scroll;
}

.svg-icon {
  padding: 5px;
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 25px;
  width: 25px;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  padding: 5px;
  text-align: center;
}

// responsive
@media (min-width: 800px) {
  width: 50vw;
}

@media (min-width: 1200px) {
  width: 60vw;
}
