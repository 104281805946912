.topLeft {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  z-index: 999; }

@media (min-width: 800px) {
  .topLeft {
    height: 48px; } }

@media (min-width: 1200px) {
  .topLeft {
    height: 56px; } }
