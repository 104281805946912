@font-face {
  font-family: 'gt-eesti';
  src: url('../fonts/gt-eesti-display-bold-webfont.eot');
  src: url('../fonts/gt-eesti-display-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/gt-eesti-display-bold-webfont.woff2') format('woff2');
  font-weight: bold;
}
@font-face {
  font-family: 'gt-eesti';
  src: url('../fonts/gt-eesti-display-regular-webfont.eot');
  src: url('../fonts/gt-eesti-display-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/gt-eesti-display-regular-webfont.ttf') format('truetype');
  font-weight: normal;
}

html, body, #app, #app>div {
  height: 100%;
  width: 100%;
  font-family: 'gt-eesti', 'Open Sans', sans-serif;
}

.fullscreen {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* extra fullscreen guarantees - you might not need the rest */
  position: absolute;
  top: 0;
  left: 0;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

.gm-style-iw-d[style] {
  overflow: hidden !important;
}


.gm-style .gm-style-iw-c[style] {
  padding: 0 !important;
  min-width: 308px;
}

.gm-style-iw-d[style] {
  min-width: 308px;
}

.gm-ui-hover-effect {
  display: none !important;
}

.map-info-window-wrapper {
  display: flex;
}

.map-info-window-container {
  display: flex;
  flex-direction: column;
}

.scrollbar::-webkit-scrollbar {
  width: 9px;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0px 6px rgba(0,0,0,0.6);
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #BCBDBC;
}


@media (min-width: 600px) {
  .map-info-window-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .gm-style .gm-style-iw-c {
    padding:0!important;
    overflow: hidden!important;
  }
}

.icon-row {
  margin-top:5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.icon-wrapper {
  border-radius: 99999px;
  background-color: #dcdce0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 10px 5px 10px 0;
}

.poi_title {
  font-size: 0.8em;
  align-self: center;
}

