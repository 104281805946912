.bottomMiddle {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  padding: 1em;
  z-index: 9999;
  margin-bottom: 20px !important; }

#fleet-logo-link {
  text-decoration: none;
  align-items: center;
  justify-content: center; }

#powered-by {
  color: #ffffff;
  text-decoration: none;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 9px; }

#fleet-logo {
  width: 50px; }

#map-search {
  width: 150px;
  font-size: 9px; }

@media (min-width: 800px) {
  #fleet-logo {
    width: 80px; }
  #powered-by {
    font-size: 12px; }
  #map-search {
    width: 200px;
    font-size: 11px; } }

@media (min-width: 1200px) {
  #fleet-logo {
    width: 120px; }
  #powered-by {
    font-size: 14px; }
  #map-search {
    width: 240px;
    font-size: 12px; } }
